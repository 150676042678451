var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSection("processing_time")
    ? _c(
        "b-card",
        {
          attrs: { id: "important-new-business-items-section", "no-body": "" },
        },
        [
          _c(
            "b-card-header",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.verify-order-accordion-5",
                  modifiers: { "verify-order-accordion-5": true },
                },
              ],
              staticClass: "accordion-header",
              class: { disabled: _vm.sectionIsDisabled("processing_time") },
              attrs: { "header-tag": "header", role: "tab" },
              on: {
                "!click": function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("set-as-current")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c(
                    "div",
                    { staticClass: "header-left processing-tab-display" },
                    [
                      _c("h4", [
                        _vm._v("\n          Processing Time\n        "),
                      ]),
                      _c(
                        "h5",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.sectionIsComplete("processing_time"),
                              expression:
                                "sectionIsComplete('processing_time') ",
                            },
                          ],
                          staticClass: "header-processing-time",
                        },
                        [
                          _c("check-circle", {
                            staticClass: "check-circle mr-2",
                            attrs: { width: "20", height: "20" },
                          }),
                          _c(
                            "span",
                            { staticClass: "mr-0 file-by-date-header" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.upsellProductInCart
                                      ? "Expedited"
                                      : "Expected"
                                  ) +
                                  " File By Date: " +
                                  _vm._s(_vm.expectedProcessingDate) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("feather-icon", { attrs: { type: "chevron-right" } }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            {
              ref: "verify-order-accordion-5",
              attrs: {
                id: "verify-order-accordion-5",
                accordion: "verify-order-accordion",
                role: "tabpanel",
                visible: _vm.currentSection === "processing_time",
              },
            },
            [
              _c(
                "b-card-body",
                [
                  _c("div", { staticClass: "processing-time" }, [
                    _c("div", { staticClass: "processing-time-left" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row w-100" },
                        [
                          _c(
                            "b-card",
                            {
                              staticClass: "w-50 first-card",
                              attrs: { "no-body": "" },
                            },
                            [
                              _c(
                                "b-card-header",
                                { attrs: { "header-tag": "header" } },
                                [
                                  _vm._v(
                                    "\n                State Processing Time\n              "
                                  ),
                                ]
                              ),
                              _c("h6", [
                                _c("strong", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.totalStateProcessingTime()) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-card",
                            {
                              staticClass: "w-50 last-card",
                              attrs: { "no-body": "" },
                            },
                            [
                              _c(
                                "b-card-header",
                                { attrs: { "header-tag": "header" } },
                                [
                                  _vm._v(
                                    "\n                Expected Filed By Date\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "processing-time-card-body",
                                  class: {
                                    productInCart: _vm.upsellProductInCart,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "vertical-rule" }),
                                  _c("h6", [
                                    _c("strong", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.expectedFileByDate) +
                                          "\n                  "
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "processing-time-right" },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "expedited",
                            attrs: { "no-body": "" },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "ct-rule-text",
                                  {
                                    attrs: { id: "expedited-price-rule-text" },
                                  },
                                  [
                                    _c(
                                      "h6",
                                      { staticClass: "expedited-price" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            "\n                    $" +
                                              _vm._s(_vm.upsellOption?.price) +
                                              " Upgrade\n                  "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-card-header",
                              { attrs: { "header-tag": "header" } },
                              [
                                _vm._v(
                                  "\n              Expedited Filed By Date\n            "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                class: {
                                  productInCart: _vm.upsellProductInCart,
                                },
                              },
                              [
                                _c("h6", [
                                  _c("strong", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.expeditedFileByDate) +
                                        "\n                "
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("ct-rule-text", [
                    _c("h4", { attrs: { id: "upgrade-available-rule-text" } }, [
                      _vm._v("\n          Upgrade Available\n        "),
                    ]),
                  ]),
                  _vm.upsellOption
                    ? _c("div", { staticClass: "upsell-info mb-3" }, [
                        _c("h2", { staticClass: "pr-2 pl-2" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.upsellHeaderText) +
                              "\n        "
                          ),
                        ]),
                        _c("p", { staticClass: "mt-3 pr-2 pl-2" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.upsellHelpText) +
                              "\n        "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-card-footer",
                {
                  staticClass: "accordion-footer",
                  attrs: { "footer-tag": "footer", role: "tab" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "standard-verify-button-size",
                      attrs: {
                        variant: "outline-primary",
                        "aria-label": "no thanks button",
                        disabled: _vm.addingOrRemovingUpsellProductToCart,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.declineFasterProcessing()
                        },
                      },
                    },
                    [
                      !_vm.removingUpsellProductFromCart
                        ? [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.upsellProductInCart
                                    ? "Remove Upgrade"
                                    : "No Thanks"
                                ) +
                                "\n        "
                            ),
                          ]
                        : _c("b-spinner", { attrs: { small: "" } }),
                    ],
                    2
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "upsell-button standard-verify-button-size button-upgrade",
                      attrs: {
                        variant: "primary",
                        "aria-label": "add product button",
                        disabled: _vm.addingOrRemovingUpsellProductToCart,
                      },
                      on: { click: _vm.addProductAndProceed },
                    },
                    [
                      !_vm.addingUpsellProductToCart
                        ? [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.upsellProductInCart
                                    ? "Continue"
                                    : "Add Upgrade"
                                ) +
                                "\n        "
                            ),
                          ]
                        : _c("b-spinner", { attrs: { small: "" } }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
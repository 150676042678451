<template>
  <b-card
    v-if="showSection('processing_time')"
    id="important-new-business-items-section"
    no-body
  >
    <b-card-header
      v-b-toggle.verify-order-accordion-5
      :class="{ disabled: sectionIsDisabled('processing_time') }"
      header-tag="header"
      class="accordion-header"
      role="tab"
      @click.stop.capture="$emit('set-as-current')"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="header-left processing-tab-display">
          <h4>
            Processing Time
          </h4>
          <h5 v-show="sectionIsComplete('processing_time') " class="header-processing-time">
            <check-circle class="check-circle mr-2" width="20" height="20" />
            <span class="mr-0 file-by-date-header">
              {{ upsellProductInCart ? 'Expedited' : 'Expected' }} File By Date: {{ expectedProcessingDate }}
            </span>
          </h5>
        </div>
        <feather-icon type="chevron-right" />
      </div>
    </b-card-header>
    <b-collapse
      id="verify-order-accordion-5"
      ref="verify-order-accordion-5"
      accordion="verify-order-accordion"
      role="tabpanel"
      :visible="currentSection === 'processing_time'"
    >
      <b-card-body>
        <div class="processing-time">
          <div class="processing-time-left">
            <div class="d-flex flex-row w-100">
              <b-card no-body class="w-50 first-card">
                <b-card-header header-tag="header">
                  State Processing Time
                </b-card-header>
                <h6>
                  <strong>
                    {{ totalStateProcessingTime() }}
                  </strong>
                </h6>
              </b-card>
              <b-card no-body class="w-50 last-card">
                <b-card-header header-tag="header">
                  Expected Filed By Date
                </b-card-header>
                <div class="processing-time-card-body" :class="{ productInCart: upsellProductInCart }">
                  <div class="vertical-rule" />
                  <h6>
                    <strong>
                      {{ expectedFileByDate }}
                    </strong>
                  </h6>
                </div>
              </b-card>
            </div>
          </div>
          <div class="processing-time-right">
            <b-card class="expedited" no-body>
              <div>
                <ct-rule-text id="expedited-price-rule-text">
                  <h6 class="expedited-price">
                    <strong>
                      ${{ upsellOption?.price }} Upgrade
                    </strong>
                  </h6>
                </ct-rule-text>
              </div>

              <b-card-header header-tag="header">
                Expedited Filed By&nbsp;Date
              </b-card-header>
              <div :class="{ productInCart: upsellProductInCart }">
                <h6>
                  <strong>
                    {{ expeditedFileByDate }}
                  </strong>
                </h6>
              </div>
            </b-card>
          </div>
        </div>
        <!-- Upgrade Available ------------------------------------------------------------>
        <ct-rule-text>
          <h4 id="upgrade-available-rule-text">
            Upgrade Available
          </h4>
        </ct-rule-text>
        <!-- Upsell Option ---------------------------------------------------------------->
        <div v-if="upsellOption" class="upsell-info mb-3">
          <h2 class="pr-2 pl-2">
            {{ upsellHeaderText }}
          </h2>
          <p class="mt-3 pr-2 pl-2">
            {{ upsellHelpText }}
          </p>
        </div>
      </b-card-body>
      <b-card-footer
        footer-tag="footer"
        class="accordion-footer"
        role="tab"
      >
        <b-button
          variant="outline-primary"
          class="standard-verify-button-size"
          aria-label="no thanks button"
          :disabled="addingOrRemovingUpsellProductToCart"
          @click="declineFasterProcessing()"
        >
          <template v-if="!removingUpsellProductFromCart" class="justify-content-no">
            {{ upsellProductInCart ? 'Remove Upgrade' : 'No Thanks' }}
          </template>
          <b-spinner v-else small />
        </b-button>

        <b-button
          class="upsell-button standard-verify-button-size button-upgrade"
          variant="primary"
          aria-label="add product button"
          :disabled="addingOrRemovingUpsellProductToCart"
          @click="addProductAndProceed"
        >
          <template v-if="!addingUpsellProductToCart" class="justify-content-no">
            {{ upsellProductInCart ? 'Continue' : 'Add Upgrade' }}
          </template>
          <b-spinner v-else small />
        </b-button>
      </b-card-footer>
    </b-collapse>
  </b-card>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProcessingTimeSection',
  components: {
    FeatherIcon: () => import('@/components/shared/FeatherIcon'),
    CheckCircle: () => import('@images/ui/check-circle.svg'),
    CtRuleText:  () => import('@/components/shared/CtRuleText'),
  },
  props: {
  },
  data() {
    return {
      addingUpsellProductToCart: false,
      removingUpsellProductFromCart: false,
    }
  },
  computed: {
    ...mapGetters('verifyOrder', [
      'currentSection',
      'expectedProcessingDate',
      'expeditedFileByDate',
      'sectionIsComplete',
      'sectionIsDisabled',
      'showSection',
      'stateProcessingWithDependentFilingDate',
      'stateProcessingDays',
      'dependentFilingStateProcessingDays',
      'expectedFileByDate',
      'upsellOption',
      'upsellOptionCartObject',
      'upsellProductInCart',
    ]),
    addingOrRemovingUpsellProductToCart() {
      return this.addingUpsellProductToCart || this.removingUpsellProductFromCart
    },
    upsellHeaderText() {
      return `Get Your Business Filed Faster`
    },
    upsellHelpText() {
      return `The state can vary in their processing times. We offer a rush processing procedure
        where your order will move to our front line rush processing service team to guarantee
        you the fastest processing available to us and you.`
    },
  },
  methods: {
    ...mapActions('checkout', [
      'addToCart',
      'removeProductIdFromCart',
    ]),
    ...mapActions('verifyOrder', [
      'logVerifyOrderInteraction',
    ]),
    async addProductAndProceed() {
      let interactionName = 'add-faster-processing'
      if (this.upsellProductInCart) {
        interactionName += '-continue'
      } else {
        this.addingUpsellProductToCart = true
        await this.addToCart({ ...this.upsellOptionCartObject, skipResolveOptionalItems: true })
        this.addingUpsellProductToCart = false
      }

      await this.logVerifyOrderInteraction(interactionName)
      this.$emit('completed')
    },

    async declineFasterProcessing() {
      let interactionName = 'decline-faster-processing'

      if (this.upsellProductInCart) {
        this.removingUpsellProductFromCart = true
        interactionName = 'remove-faster-processing'
        await this.removeProductIdFromCart(this.upsellOptionCartObject.id)
      }

      await this.logVerifyOrderInteraction(interactionName)
      this.removingUpsellProductFromCart = false
      this.$emit('completed')
    },
    totalStateProcessingTime() {
      const total = this.stateProcessingDays + this.dependentFilingStateProcessingDays
      return !total || total === 1
        ? '1 Business Day'
        : `${total} Business Days`
    },
  },
}
</script>

<style lang="scss">
$gray2: #A7A7A7;

#important-new-business-items-section {

  .accordion-header {

    div {

      div.header-left {

        h5.header-processing-time {

          span.file-by-date-header {
            color: $ct-ui-primary !important;
            fill: $ct-ui-primary !important;
          }
        }
      }
    }
  }

  #expedited-price-rule-text {
    position: absolute;
    top: -3.125em;
  }

  #upgrade-available-rule-text {
    font-weight: 800;
    white-space: nowrap;
    color: $ct-ui-primary !important;
  }

  #upsell-in-cart-check-circle {
    margin-right: 0.375em;
    margin-bottom: 0.125em;
  }

  .upsell-info {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 100px;

    h2 {
      font-size: 2.2rem;
      font-weight: 400;
    }
    p {
      color: $ct-ui-color-19;
      max-width: 47.5em;
    }
  }

  .upsell-button {
    &.disabled {
      border-color: $gray2;
      background: $gray2;
    }
    .check-circle {
      path { fill: white !important }
    }
  }

  .processing-time {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 4em;
    margin-top: 3.75em;
    margin-bottom: 4.375em;

    .card {
      min-width: 12.5em;
      margin: 0;
      border-radius: 0.5em;

      h6 {
        flex-grow: 1;
        text-align: center;
        margin: 0 !important;
        padding: 0.875em !important;
      }
      .card-header {
        text-align: center;
        border-radius: 0.375em 0.375em 0 0 !important;
        font-size: 0.85em;
        color: white;
        font-weight: 800;
        padding: 0.563em;
        background: #4b4b4b;
      }
      .processing-time-card-body {
        display: flex;
      }
      &.expedited {
        border-color: $ct-ui-primary;
        flex-grow: 1;

        .card-header {
          background: $ct-ui-primary;
        }
        .expedited-price {
          color: $ct-ui-color-19;
        }
      }
    }
    .processing-time-left {
      display: flex;
      flex-direction: row;

      .card {
        border-color: #4b4b4b;

        &.first-card {
          border-radius: 0.5em 0 0 0.5em !important;
          border-right: none;
          .card-header {
            border-radius: 0.375em 0 0 0 !important;
          }
        }
        &.last-card {
          display: flex;
          border-radius: 0 0.5em 0.5em 0 !important;
          border-left: none;

          .vertical-rule {
            margin: 0.625em 0;
            border-right: solid lightgrey 1px !important;
          }
          .card-header {
            border-radius: 0 0.375em 0 0 !important;
          }
        }
      }
    }
  }

  #verify-order-accordion-5 {

    .accordion-footer {

      .button-upgrade {
        background: $ct-ui-primary !important;
        border-color: $ct-ui-primary !important;

        &:hover {
          background: $ct-ui-primary-dark !important;
          border-color: $ct-ui-primary !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  #verify-order {
    .accordion {
      .processing-time {
        flex-direction: column;
        margin-top: 0.688em;

        .card {
          min-width: 0;
        }
        .processing-time-left {
          .card {
            margin: auto !important;

            .card-header {
              font-size: 0.75em;
            }
          }
        }
      }
    }
  }
}

</style>
